<template>
    <BasePage>
        <titlu-pagina Titlu="Preturi depozitare roti" @on_add_clicked="show_dialog()" :AdaugaVisible="true" />        
        <el-table :data="Results" default-expand-all>
            <el-table-column type='expand' width="50px">
                <template slot-scope='props'>
                    <el-table :data="props.row.Intrari">
                        <el-table-column data="Categorie" label="Dimensiune">
                            <template slot-scope="scope">{{scope.row.Categorie}}</template>
                        </el-table-column>
                        <el-table-column data="Pret" label="Pret">
                            <template slot-scope="scope">{{scope.row.Pret}} lei </template>                                                        
                        </el-table-column>
                        <el-table-column fixed="right" label="Actiuni" width="200px" >
                            <template slot-scope="scope" >
                                <el-tooltip content="Modificare">
                                    <el-button type="primary" icon="el-icon-edit" circle @click="show_dialog(scope.row.Id)" />
                                </el-tooltip>
                                <el-tooltip content="Sterge" >
                                    <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>   
            <el-table-column prop='Nume' label='Categorie'>
                <template slot-scope="scope">
                    <el-tag :type="typeOfTag(scope.row.Nume)" >{{ scope.row.Nume }}</el-tag>
                </template>
            </el-table-column>         
        </el-table>
        <PreturiDepozitareRotiDialog ref='dlg' @save="refresh_info()" />
    </BasePage>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Preturi_depozitare_roti_dialog from '@/pages/preturi_depozitare_roti/Preturi_depozitare_roti_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "preturi_depozitare_roti",
    extends: BasePage,
    components: {
        'BasePage': BasePage,
        'PreturiDepozitareRotiDialog': Preturi_depozitare_roti_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { }
        }
    },
    computed: {
        
    },
    methods: {
        async get_info(){          
            var response = await this.post("preturi_depozitare_roti/get_info" );
            this.refresh_info();
        },

        async refresh_info(){
            var response    = await this.post("Preturi_depozitare_roti/index");
            this.Results    = response.Results;
            this.select_menu_item('preturi_depozitare_roti');
        },
        reset(){
            this.refresh_info();
        },
        typeOfTag(param){
            if(param.includes('fara'))
                return 'info'
            else
                return 'primary'
        },
        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("preturi_depozitare_roti/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
