<template>
    <el-dialog title="Categorii roti" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Nume' prop="Nume">
                        <el-select class='full-width' v-model='selectedObject.Nume' >
                            <el-option v-for='(item,key) in tip_roti' :key="key" :label='item' :value='item'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Categorie roata' >
                        <el-select class='full-width' v-model='selectedObject.IdCategorieRoti' >
                            <el-option v-for='item in Info.categorii_roti' :key="'categorii_roti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Pret' >
                        <el-input-number v-model="selectedObject.Pret"></el-input-number>
                    </el-form-item>
                </el-col>
                           
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm()"> Renunta  </el-button>
			<el-button type="primary" @click="save()" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Categorii_roti_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                mode: 'add',
                selectedObject: {Nume: '' , IdCategorieRoti: '', Pret: 0 },                    
                Info:{ categorii_roti:[] },                                    
                rules: {
                    Nume:               [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdCategorieRoti:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                },
                tip_roti:['Anvelope fara janta','Anvelope cu janta']                
            }
        },
        methods: {
            async show_me( id ) {
                this.selectedObject = {Nume: '' , IdCategorieRoata:'', Pret: 0 }
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("preturi_depozitare_roti/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                }
            },
            async get_info(){
                var response                = await this.post("preturi_depozitare_roti/get_info_for_dialog" );
                this.Info.categorii_roti    = response.categorii_roti
            },
            async save() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("preturi_depozitare_roti/save", { mode: this.mode, object: this.selectedObject } );
                        this.resetForm()
                        this.$emit("save");
                    } 
                    else 
                        console.log('eroare validare formular!')
                });
            },            
			resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
			}
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>