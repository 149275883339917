import { render, staticRenderFns } from "./Preturi_depozitare_roti_list.vue?vue&type=template&id=83ce3798&scoped=true&"
import script from "./Preturi_depozitare_roti_list.vue?vue&type=script&lang=js&"
export * from "./Preturi_depozitare_roti_list.vue?vue&type=script&lang=js&"
import style0 from "./Preturi_depozitare_roti_list.vue?vue&type=style&index=0&id=83ce3798&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83ce3798",
  null
  
)

export default component.exports